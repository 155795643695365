<template>
    <div class="item row align-items-center pb-4 pb-xl2-0 pt-xl2-3 mb-4 mb-xl2-0 mt-xl2-0 position-relative">

        <div class="item-id col-auto text-center px-1"
             :style="{width: '50px'}">{{ item.id }}
        </div>
        <div class="item-wrap">
            <div class="item-header d-flex d-xl2-none align-items-center"
                 @click="editing = !editing"
            >
                <span class="mr-auto text-nowrap text-truncate">
                    {{ item.name }}
                    <template v-if="item.width &&  item.height && item.length">
                        {{ item.width }}x{{ item.height }}x{{ item.length }}
                    </template>
                </span>

                <button v-if="item.isDirty" class="btn btn-sm btn-edit" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                </button>

                <button v-if="canRemove && windowWidth <= 1359" class="btn btn-sm btn-remove ml-sm-3"
                        @click="remove"
                        type="button"
                        role="presentation"
                        tabindex="-1">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                    </svg>
                </button>
            </div>

            <div class="item-settings row align-items-end align-items-xl2-center"
                 v-show="showSettings()"
            >
                <div class="item-name col">
                    <label :for="`name_${item.id}`" class="d-xl2-none">Name</label>
                    <Input :id="`name_${item.id}`"
                           placeholder="item name"
                           autocomplete="off"
                           :name="`name[${item.id}]`"
                           :value="item.name"
                           @input="item.name = $event.target.value"
                           :v="v ? v.name : undefined"
                           :showError="false"
                    />
                </div>

                <div class="item-weight col text-center">
                    <label :for="`weight_${item.id}`" class="d-xl2-none">Weight</label>
                    <Input :id="`weight_${item.id}`"
                           type="number"
                           :min="0"
                           placeholder="weight"
                           :name="`weight[${item.id}]`"
                           className="text-center"
                           :value="item.weight"
                           @input="item.weight = $event.target.value"
                           :v="v ? v.weight : undefined"
                           :showError="false"
                    />
                </div>

                <div class="item-length col text-center">
                    <label :for="`length_${item.id}`" class="d-xl2-none">Length</label>
                    <Input :id="`length_${item.id}`"
                           type="number"
                           :min="0"
                           placeholder="length"
                           :name="`length[${item.id}]`"
                           className="text-center"
                           :value="item.length"
                           @input="item.length = $event.target.value"
                           :v="v ? v.length : undefined"
                           :showError="false"
                    />
                </div>

                <div class="item-width col text-center">
                    <label :for="`width_${item.id}`" class="d-xl2-none">Width</label>
                    <Input :id="`width_${item.id}`"
                           type="number"
                           :min="0"
                           placeholder="width"
                           :name="`width[${item.id}]`"
                           className="text-center"
                           :value="item.width"
                           @input="item.width = $event.target.value"
                           :v="v ? v.width : undefined"
                           :showError="false"
                    />
                </div>

                <div class="item-height col col-height text-center">
                    <label :for="`height_${item.id}`" class="d-xl2-none">Height</label>
                    <Input :id="`height_${item.id}`"
                           type="number"
                           :min="0"
                           placeholder="height"
                           :name="`height[${item.id}]`"
                           className="text-center"
                           :value="item.height"
                           @input="item.height = $event.target.value"
                           :v="v ? v.height : undefined"
                           :showError="false"
                    />
                </div>

                <div class="item-stackable col col-stackable text-center">
                    <label :for="`stackable_${item.id}`" class="d-xl2-none">Stackable</label>
                    <Checkbox
                        :id="`stackable_${item.id}`"
                        :name="`stackable[${item.id}]`"
                        :checked="item.stackable || 0"
                        @change="item.stackable = $event.value || 0"
                        :showError="false"
                    />
                </div>

                <div
                    class="item-quantity col-auto text-center d-flex justify-content-center justify-content-sm-end justify-content-xl2-center align-items-center"
                    :class="{'text-danger': !isValidQuantity}">
                    <button class="btn btn-sm btn-quantity"
                            @click="minusQuantity"
                            type="button"
                            role="presentation"
                            tabindex="-1">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                        </svg>
                    </button>
                    <span v-show="!showInput"
                          class="cursor-pointer item-quantity-value"
                          @click="showInput = !showInput">{{ item.quantity }}</span>
                    <Input class="item-quantity-value-input"
                           ref="item_quantity"
                           name="item_quantity"
                           v-show="showInput"
                           type="number"
                           class-name="text-center form-control-text px-1"
                           :min="1"
                           :value="item.quantity"
                           @focusout="showInput = !showInput"
                           @input="item.quantity = $event.target.value < 1 ? 1 : $event.target.value"
                           :style="{width: '40px'}"
                    />
                    <button class="btn btn-sm btn-quantity"
                            @click="item.quantity++"
                            type="button"
                            role="presentation"
                            tabindex="-1">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </button>
                    <button v-if="canRemove && windowWidth > 1359" class="btn btn-sm btn-remove"
                            @click="remove"
                            type="button"
                            role="presentation"
                            tabindex="-1">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import Input from "@/components/form/Input";
import Checkbox from "@/components/form/Checkbox";
import {mapGetters} from "vuex";

export class ItemObject {
    name = null;
    weight = null;
    length = null;
    width = null;
    height = null;
    stackable = 0;
    quantity = 1;
    isDirty = false;

    constructor(id) {
        this.id = id;
    }
}

export default {
    name: "Item",
    components: {Checkbox, Input},
    data: () => ({
        editing: false,
        showInput: false,
        windowWidth: window.innerWidth,
    }),
    props: {
        item: {
            required: true,
            type: Object,
        },
        canRemove: {
            type: Boolean,
            default: true
        },
        v: [Object, String, undefined, null],
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    watch: {
        item: {
            deep: true,
            handler(value) {
                value.isDirty = true;
                this.editing = true
            }
        },
        showInput(value) {
            if (value) {
                setTimeout(() => this.$refs.item_quantity.$el.querySelector('input').focus(), 50)
            }
        }
    },
    methods: {
        showSettings() {
            let hasError = false;
            if (this.v && this.v.$error)
                hasError = this.v.$error;

            return !this.item.isDirty || this.editing || this.windowWidth > 1359 || hasError
        },

        onResize() {
            this.windowWidth = window.innerWidth
        },
        minusQuantity() {
            const {item, canRemove} = this;
            if (!canRemove && item.quantity == 1) return;
            if (item.quantity - 1 == 0) {
                this.remove()
            } else if (item.quantity > 1)
                item.quantity--
        },

        remove() {
            const {item} = this;
            if (confirm('Are you sure you want to delete this item?'))
                this.$emit('removeItem', item);
        },

    },
    computed: {
        isValidQuantity() {
            if ([null, undefined].indexOf(this.v) > -1 ||
                [null, undefined].indexOf(this.v.quantity) > -1) return true;

            if(typeof this.v.quantity === 'string') return false;
            else return !this.v.quantity.$error
        },

        ...mapGetters({
            user: 'auth/user',
        }),
    }
}
</script>

<style scoped lang="scss">

.item {
    padding-right: 50px;
    justify-content: space-between;

    @include media-breakpoint-only('xl2') {
        padding-right: 35px;
    }
    @include media-breakpoint-down('xl') {
        border-bottom: 1px solid $gray-100;
        padding-left: 50px;
        padding-right: 15px;
    }
    @include media-breakpoint-down('xs') {
        padding-left: 40px;
    }

    &:hover {
        .btn-remove {
            opacity: 1;
            visibility: visible;
        }
    }

    &-wrap {
        @include media-breakpoint-up('xl2') {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }
        @include media-breakpoint-down('xl') {
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
            height: $card-height;
            word-wrap: break-word;
            background-color: $card-bg;
            background-clip: border-box;
            border: $card-border-width solid $card-border-color;
            @include border-radius($card-border-radius);
            width: 100%;
        }
    }

    &-header {
        flex: 1 1 auto;
        min-height: 1px;
        padding: $card-spacer-x;
        color: $card-color;
        cursor: pointer;
    }

    &-settings {
        @include media-breakpoint-down('xl') {
            margin: 0 !important;
            padding: 0 0 1.25rem;
        }

        @include media-breakpoint-down('sm') {
            padding-left: calc(1.25rem - 5px);
            padding-right: calc(1.25rem - 5px);
        }
    }

    &-name,
    &-weight,
    &-length,
    &-width,
    &-height,
    &-stackable,
    &-quantity {
        @include media-breakpoint-only('xl2') {
            padding-left: 5px;
            padding-right: 5px;
        }
        @include media-breakpoint-down('sm') {
            padding-left: 5px;
            padding-right: 5px;
        }

    }

    &-id {
        @include media-breakpoint-down('xl') {
            position: absolute;
            left: 0;
            top: 8px;
        }
        @include media-breakpoint-down('xl') {
            width: 40px !important;
            padding-top: 1.25rem;
            top: 2px;
        }
    }

    &-name {
        @include media-breakpoint-down('xl') {
            max-width: calc(100% - 255px);
            flex: 0 0 calc(100% - 255px);
        }

        @include media-breakpoint-down('sm') {
            max-width: calc(100% - 210px);
            flex: 0 0 calc(100% - 210px);
        }

        @include media-breakpoint-down('xs') {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    &-stackable {
        max-width: 100px;
        flex: 0 0 100px;


        @include media-breakpoint-down('xl') {
            order: 1;
        }
        @include media-breakpoint-down('sm') {
            max-width: 80px;
            flex: 0 0 80px;
        }
        @include media-breakpoint-down('xs') {
            display: flex;
            max-width: 50%;
            flex: 0 0 50%;
            margin-top: 1.25rem;
            justify-content: space-around;
            align-items: center;
        }
        @include media-breakpoint-down-px('399') {
            max-width: 100%;
            flex: 0 0 100%;
            order: 3;
        }
    }

    &-quantity {
        @include media-breakpoint-down('xl') {
            order: 1;
            max-width: 155px;
            flex: 0 0 155px;
        }
        @include media-breakpoint-down('sm') {
            max-width: 130px;
            flex: 0 0 130px;
        }
        @include media-breakpoint-down('xs') {
            max-width: 50%;
            flex: 0 0 50%;
        }
        @include media-breakpoint-down-px('399') {
            max-width: 100%;
            flex: 0 0 100%;
            order: 0;
            margin-top: 1.25rem;
        }

        &-value {
            width: 40px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @include media-breakpoint-down('xs') {
                flex-basis: 100%;
            }

            &-input {
                @include media-breakpoint-down('xs') {
                    flex-basis: 100%;
                }
            }
        }

        .btn {
            @include media-breakpoint-down('xs') {
                border-color: $gray-200;
                flex-basis: 100%;
            }
        }

    }

    &-weight,
    &-length,
    &-width,
    &-height {
        max-width: 115px;
        flex: 0 0 115px;
        @include media-breakpoint-down('xl2') {
            max-width: 105px;
            flex: 0 0 105px;
        }
        @include media-breakpoint-down('xl') {
            order: 2;
            max-width: 25%;
            flex: 0 0 25%;
            margin-top: 1.25rem;
        }

        @include media-breakpoint-down('sm') {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }


}

.btn-quantity {
    font-size: $font-size-base * 1.5;
    line-height: 0.75em;
}

.btn-edit {
    pointer-events: none;
    line-height: 0.6em;
}

.btn-remove {

    .item-settings & {
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-up('xl2') {
            opacity: 0;
            visibility: hidden;
        }
    }

    line-height: 1em;
    transition: opacity 0.3s ease,
    visibility 0.3s ease;


    path {
        transition: fill 0.3s ease;
    }

    &:hover, &:focus {
        path {
            fill: $danger;
        }
    }
}

</style>