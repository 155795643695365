<template>
    <div ref="selectWrapper">
        <label v-if="label" :for="id || name">{{ label }}</label>
        <span class="custom-select cursor-pointer"
              :class="{disabled, readonly, 'is-invalid': !isValid, [className]: className}"
              @click="show = !show">
            {{ castValue }}
        </span>
        <transition name="fade">
            <div v-if="options && show && !disabled && !readonly" class="dropdown">
                <div class="dropdown-menu">
                    <template v-for="(option, index) in options">
                        <a v-if="![null,undefined,'',0].includes(option)"
                            class="dropdown-item"
                           :class="{'active': isSelected(index)}"
                           href="#"
                           :key="`${option}-${index}`"
                           @click.prevent="() => setValue(index)"
                        >
                            {{ option }}
                        </a>
                    </template>
                </div>
            </div>
        </transition>
        <input v-if="!multiple"
               type="hidden"
               :id="id || name"
               :name="name"
               :disabled="disabled"
               :required="required"
               :readonly="readonly"
               :value="value"
        />
        <div v-if="!isValid && typeof v === 'string' && showError" class="invalid-feedback text-nowrap">{{ v }}</div>
        <div v-else-if="!isValid && !v.required && typeof v.required !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">The field is required.
        </div>
        <template v-if="multiple">
            <input
                v-for="seleced in value"
                type="hidden"
                :name="`${name}[]`"
                :key="`seleced-${seleced}`"
                :value="seleced"
                :disabled="disabled"
                :required="required"
                :readonly="readonly"
            />
        </template>
        
    </div>
</template>

<script>
export default {
    name: "Select",
    data: () => ({
        show: false,
        value: null,
    }),
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: [Object, Array],
            default: () => [],
        },
        selected: [String, Number],
        label: String,
        id: String,
        className: String,
        placeholder: String,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        multiple: {
            type: Boolean,
            default: false,
        },
        autofocus: Boolean,
        size: Number,
        showError: {
            type: Boolean,
            default: true,
        },
        v: [Object, String, undefined, null],
    },
    mounted() {
        this.value = this.multiple ? [] : this.value;
        if (this.selected) {
            if (this.multiple) this.value.push(this.selected)
            else this.value = this.selected;
        }
        document.addEventListener('click', this.hideOptions);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.hideOptions);
    },
    watch: {
        selected(selected) {
            if (selected) {
                if (this.multiple) this.value.push(selected)
                else this.value = selected;
            } else if(this.options){
                if(Array.isArray(this.options)){
                    this.setValue(0)
                } else{
                    this.setValue(Object.keys(this.options)[0]);
                }
            }
        }
    },
    computed: {
        castValue() {
            if (this.multiple && Array.isArray(this.value) && this.value.length) {
                return this.value.join(', ');
            } else if (this.value && this.options) {
                return this.options[this.value];
            } else {
                return this.placeholder || 'choose option';
            }
        },
        isValid() {
            if ([null, undefined].indexOf(this.v) > -1) return true;
            return typeof this.v === 'string' ? false : !this.v.$error
        }
    },
    methods: {

        setValue(index) {
            const option = this.options[index];
            if (this.multiple && Array.isArray(this.value)) {
                if (this.value.length && this.value.includes(option)) {
                    const indexIn = this.value.indexOf(option);
                    this.value.splice(indexIn, 1);
                } else {
                    this.value.push(option);
                }
            } else {
                this.value = index;
                this.show = !this.show;
            }
            this.$emit('change', {name: this.name, value: this.value});
        },
        hideOptions(e) {
            if (!e || !e.target || !this.$refs.selectWrapper) return;
            if (!this.$refs.selectWrapper.contains(e.target) && this.show) {
                this.show = false;
            }
        },
        isSelected(option) {
            if (this.multiple && Array.isArray(this.value) && this.value.includes(option)) {
                return true;
            } else if (typeof this.value == 'string' && this.value === this.options[option]) {
                return true;
            }
            return false;
        }
    }
}
</script>

<style scoped lang="scss">
span {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    @include text-truncate;

    &.disabled,
    &.readonly {
        background-color: $input-disabled-bg;
        opacity: 1;
    }
}

.dropdown {
    &-menu {
        display: block;
        z-index: 9;
        min-width: 100%;
        max-width: 100%;
        max-height: 370px;
        overflow: auto;
    }

    &-item {
        @include text-truncate;
    }
}

.fade-leave-active,
.fade-enter-active {
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;

    .dropdown-menu {
        transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    }
}

.fade-enter, .fade-leave-to {
    .dropdown-menu {
        opacity: 0;
        transform: scale(0.75) translateY(-19px);
    }
}

.fade-enter-to, .fade-leave {
    .dropdown-menu {
        opacity: 1;
        transform: scale(1) translateY(0);
        pointer-events: auto;
    }
}

</style>