import {default as oneOfValidator} from './oneOf';
import {default as notSameAsValidator} from './notSameAs';
import {default as addressTypeValidator} from "./addressType";
import {default as hourValidator} from "./hour";
import {default as isArrayValidator} from "./isArray";
import {default as notAddressTypeValidator} from "./notAddressType";

export const oneOf = oneOfValidator;
export const notSameAs = notSameAsValidator;
export const addressType = addressTypeValidator;
export const notAddressType = notAddressTypeValidator;
export const hour = hourValidator;
export const isArray = isArrayValidator;

export default {
    oneOf,
    notSameAs,
    addressType,
    notAddressType,
    hour,
    isArray,
}