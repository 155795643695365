const config = {

    use: 'here',

    services: {
        here: {
            js_api_key: 'X-4kM7rgZAVb0sKo66lUOO_qGUY3d7RUH1V14y1IcmY',
            url: 'https://geocode.search.hereapi.com',
            endpoints: {
                discover: '/v1/discover',
                autosuggest: '/v1/autosuggest',
                geocode: '/v1/geocode',
            },
            rest: {
                apiKey: 'LQLPN6KQgFqOS0BxOTnhOQQp7KcvPsK08LWaFW97B8I',
                limit: 10,
                lang: 'en',
            },
            map: {
                zoom: 5,
                defaultCenter: {
                    lat: 48.499998,
                    lng: 23.3833318,
                }
            },
            routing: {
                transportMode: 'car',
                routingMode: 'short',
                return: 'polyline',
                alternatives: 0,
                'exclude[countries]': 'CHE,SRB',
            }
        },
        google_maps: {
            url: 'https://maps.googleapis.com',
            endpoints: {
                place_search: '/maps/api/place/findplacefromtext/json',
                place_details: '/maps/api/place/details/json',
                place_autocomplete: '/maps/api/place/autocomplete/json',
                query_autocomplete: '/maps/api/place/queryautocomplete/json',
            },
            rest: {
                key: 'AIzaSyAlq1RsZt-DtwmZQnHimOCQnSBVDrb68PI',
                language: 'en',
            },
            map:{
            },
            routing: {
            }

        },
        openrouteservice: {
            url: "https://api.openrouteservice.org",
            endpoints: {
                geocode_search: "/geocode/search", // GET
                geocode_autocomplete: "/geocode/autocomplete", // GET
                geocode_search_structured: "/geocode/search/structured", // GET
                geocode_reverse: "/geocode/reverse" // GET
            },
            rest: {
                api_key: '5b3ce3597851110001cf6248bc0a43346f7f4887ba1843ccc92cabee',
                size: 10,
            },
            map:{
            },
            routing: {
            }
        }
    }
}

export default config;