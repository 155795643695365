<template>
    <div class="custom-control custom-radio">
        <input type="radio" class="custom-control-input"
               :id="id || name"
               :name="name"
               :value="value"
               :checked="checked"
               :disabled="disabled"
               :class="{'is-invalid' : !isValid}"
                @change="handleChange">
        <label class="custom-control-label" :for="id || name">{{ label }}
            <Tooltip v-if="$attrs.tooltip"
                     :style="{marginLeft: '5px'}"
                     :content="$attrs.tooltip"
            />
        </label>
        <div v-if="!isValid && typeof v === 'string' && showError" class="invalid-feedback text-nowrap">{{ v }}</div>
        <div v-else-if="!isValid && !v.required && typeof v.required !== 'undefined' && showError" class="invalid-feedback text-nowrap">The field is required.</div>
        <div v-else-if="!isValid && !v.alphaNum && typeof v.alphaNum !== 'undefined' && showError" class="invalid-feedback text-nowrap">Only alphanumeric characters are allowed.</div>
        <div v-else-if="!isValid && !v.alpha && typeof v.alpha !== 'undefined' && showError" class="invalid-feedback text-nowrap">Only alphabetic characters are allowed.</div>

    </div>
</template>

<script>
import Tooltip from "@/components/notices/InfoTooltip";
export default {
    name: "Radio",
    components: {Tooltip},
    props: {
        name: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number],
            default: 1,
        },
        label: {
            type: String,
            required: true,
        },
        id: String,
        className: String,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        showError: {
            type: Boolean,
            default: true,
        },
        v: [Object,String,undefined,null],
    },
    methods: {
        handleChange(){
            const {name, disabled, readonly, value} = this;
            if(disabled || readonly) return;
            this.$emit('change',{name,value});
        }
    },
    computed: {
        isValid() {
            if([null,undefined].indexOf(this.v) > -1) return true;
            return typeof this.v === 'string' ? false : !this.v.$error        }
    },
}
</script>

<style scoped lang="scss">
.custom-radio{
    margin-bottom: 0.45rem;
}
.custom-control-label{
    font-weight: $font-weight-normal;
}
</style>