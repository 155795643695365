<template>
    <PrivateLayout v-if="loaded">
        <div class="card border-none mt-4">
            <form class="card-body py-lg-4 px-lg-5"
                  @submit.prevent="handleSubmit">
                <h4 class="text-primary font-weight-semibold mt-3">New Quote</h4>
                <ShippingRoute
                    @change="handleChange"
                    :form="form"
                    :v="$v.form"
                    :vServer="$vServer"
                    :transportTypes="transportTypes"
                />
                <Items
                    @changeItems="handleChange"
                    @change="handleChange"
                    :form="form"
                    :v="$v.form"
                    :vServer="$vServer"
                />

                <ServiceDetails
                    @change="handleChange"
                    :form="form"
                    :v="$v.form"
                    :vServer="$vServer"
                    :vehicles="vehicles"
                    :disableTruck="!checkVehicleTypeRequired()"
                    :showTruck="hasTruckTransportType()"
                />
                <div class="form-group text-right mt-6">
                    <button type="submit"
                            class="btn btn-primary btn-lg"
                            :disabled="submitted || !Object.keys(vehicles).length"
                    >
                        Send a quote
                    </button>
                </div>
            </form>
        </div>

        <CertifiedLogos class="mt-6 pt-5"/>

    </PrivateLayout>
</template>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import {integer, minValue, numeric, required, requiredIf} from 'vuelidate/lib/validators'
import ShippingRoute from "@/shared/new-quote/ShippingRoute";
import Items from "@/shared/new-quote/Items";
import ServiceDetails from "@/shared/new-quote/ServiceDetails";
import CertifiedLogos from "@/components/CertifiedLogos";
import {notSameAs, notAddressType, isArray} from "@/validators";
import {name} from "@/config/app";
import {scrollToError} from "@/helpers";
import {transportTypes} from "@/config/checkout";
import config from "@/config/maps";
import objectPath from 'object-path'
import oneOf from "../../validators/oneOf";

export default {
    name: 'NewQuote',
    meta: {
        title: `New Quote - ${name}`
    },
    middleware: 'auth',

    data: () => ({
        loaded: false,
        submitted: false,
        form: {
            transport_types: [],
            from: null,
            to: null,
            collection_date: null,
            items: [],
            items_unit: null,
            service_details: {
                vehicle_type: null,
                temp_conditions: null,
                additional_services: [],
                insurance_value: null,
                class_hazardous_goods: null,
            },
        },
        transportTypes
    }),
    components: {
        ServiceDetails,
        ShippingRoute,
        Items,
        CertifiedLogos,
    },
    validations: {
        form: {
            transport_types: {
                required,
                isArray,
                $each: {
                    oneOf: oneOf(Object.values(transportTypes))
                }
            },
            from: {
                required,
                notSameAs: notSameAs('to'),
                notAddressType: notAddressType(['city', 'district', 'administrativeArea'], ['AN', 'EU', 'NA', 'OC', 'SA']),
                notAddressTypeForAsia: notAddressType(['district', 'administrativeArea'], ['AF', 'AS'])
            },
            to: {
                required,
                notSameAs: notSameAs('from'),
                notAddressType: notAddressType(['city', 'district', 'administrativeArea'], ['AN', 'EU', 'NA', 'OC', 'SA']),
                notAddressTypeForAsia: notAddressType(['district', 'administrativeArea'], ['AF', 'AS'])
            },
            items_unit: {required},
            items: {
                required,
                $each: {
                    name: {
                        required,
                    },
                    weight: {
                        required,
                        numeric,
                        minValue: minValue(1),
                    },
                    length: {
                        required,
                        numeric,
                        minValue: minValue(1),
                    },
                    width: {
                        required,
                        numeric,
                        minValue: minValue(1),
                    },
                    height: {
                        required,
                        numeric,
                        minValue: minValue(1),
                    },
                    quantity: {
                        required,
                        integer,
                        minValue: minValue(1)
                    },
                }
            },
            collection_date: {required},
            service_details: {
                vehicle_type: {
                    required: requiredIf(function () {
                        if (this.checkVehicleTypeRequired())
                            return this.checkVehicleTypeRequired()
                                ? this.hasTruckTransportType()
                                : false;
                    })
                },
                temp_conditions: {
                    required: requiredIf(model => model.vehicle_type === 'temperature')
                },
                insurance_value: {
                    required: requiredIf(model => model.insurance)
                },
                class_hazardous_goods: {
                    required: requiredIf(model => model.additional_services.includes('hazardous_goods'))
                }
            }
        },
    },

    async beforeCreate() {

        await Promise.all([
            this.$store.dispatch('units/fetchVehicles'),
            this.$store.dispatch('units/fetchCurrencies'),
            this.$store.dispatch('units/fetchUnits'),
        ]);

        if (this.$route.params.type) {
            this.form.transport_types = [this.$route.params.type];
        }

        this.form.items_unit = this.$cookies.isKey('items-unit')
            ? this.$cookies.get('items-unit')
            : this.user.settings.unit;

        try {
            if (this.newQuote)
                this.form = this.newQuote;
            else if (this.quote) {
                await this.parseQuoteToFormData(this.quote)
            } else if (this.checkReference) {
                await this.$store.dispatch('checkout/fetchNewQuote')
                const quote = this.$store.getters['checkout/quote'];

                if (quote)
                    await this.parseQuoteToFormData(quote)
            }
        } catch (e) {
            console.error(e.message)
        }
        this.loaded = true;
    },

    watch: {
        paramTransportType(value) {
            this.$v.form.$reset(value);
            if (value) {
                this.form.transport_types = [value]
            }
        },
        form: {
            deep: true,
            handler() {
                if (!this.hasTruckTransportType()) {
                    this.resetSelectedVehicleType()
                }
            },
        }
    },
    computed: {
        ...mapGetters({
            '$vServer': 'serverNotice/errors',
            quote: 'checkout/quote',
            newQuote: 'checkout/newQuote',
            reference: 'checkout/reference',
            checkReference: 'checkout/check',
            user: 'auth/user',
        }),
        paramTransportType() {
            return this.$route.params.type
        },

        vehicles() {
            const vehicles = this.allVehicles;
            if (!vehicles) return {};

            return vehicles;
        },

        allVehicles() {
            return this.$store.getters['units/vehicles'];
        },
    },
    methods: {
        hasTruckTransportType() {
            return this.form.transport_types && this.form.transport_types.indexOf(this.transportTypes.TRUCK) > -1;
        },

        resetSelectedVehicleType() {
            this.form.service_details.vehicle_type = null;
        },

        postForm() {
            const {
                from,
                to,
                collection_date,
            } = this.form;

            const post = {
                ...this.form, ...{
                    from: {
                        address: from?.address || '',
                        position: from && from.position ? `${from.position.lat},${from.position.lng}` : '',
                        resultType: from.resultType,
                    },
                    to: {
                        address: to?.address || '',
                        position: to && to.position ? `${to.position.lat},${to.position.lng}` : '',
                        resultType: to.resultType,
                    },
                    collection_date: moment(collection_date).format('DD-MM-YYYY'),
                }
            }
            return JSON.stringify(post);
        },

        handleChange({name, value}) {
            if (!name) return;
            if (name.indexOf('additional_services') > -1) {
                const regExp = /\[([^)]+)\]/;
                const matches = regExp.exec(name);
                if (matches.length == 2) {
                    const [, nameCheckbox] = matches;
                    if (value) {
                        if (!this.form.service_details.additional_services.includes(nameCheckbox)) {
                            this.form.service_details.additional_services.push(nameCheckbox);
                        }
                    } else {
                        while (this.form.service_details.additional_services.includes(nameCheckbox)) {
                            const keyNameCheckbox = this.form.service_details.additional_services.indexOf(nameCheckbox);
                            if (keyNameCheckbox > -1) {
                                this.form.service_details.additional_services.splice(
                                    this.form.service_details.additional_services.indexOf(nameCheckbox), 1
                                )
                            }
                        }
                    }
                }
            } else {
                objectPath.set(this.form, name, value)
            }
        },

        async handleSubmit() {
            if (this.submitted) return;
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                this.$nextTick(() => scrollToError(this.$el))
            } else {
                this.submitted = true;
                this.$store.dispatch('serverNotice/clearNotice');

                try {
                    this.$root.$preloader.start();
                    const {data} = await this.$store.dispatch('checkout/dispatchNewQuote', this.postForm());
                    this.$store.dispatch('checkout/setNewQuote', this.form);
                    this.$store.dispatch('checkout/setQuote', data);
                    this.$store.dispatch('checkout/saveReference', data.reference_key);

                    this.$router.push({path: '/quote-summary'})

                } catch (e) {
                    const {data, status} = e.response;
                    if (!data.status)
                        data.status = status;
                    this.$store.dispatch('serverNotice/setNotice', data);
                    this.$root.$preloader.finish();
                    this.$nextTick(() => scrollToError(this.$el.querySelector('.is-invalid')))
                }
                this.submitted = false;
            }
        },


        async parseQuoteToFormData(quote) {
            const mapConfig = config.services[config.use];
            const {from, to, collection_date} = quote;

            const {data: fromData} = await this.$http.get(`${mapConfig.url}${mapConfig.endpoints.geocode}`, {
                params: {
                    ...mapConfig.rest,
                    limit: 1,
                    q: from.address.label,
                }
            });
            const {data: toData} = await this.$http.get(`${mapConfig.url}${mapConfig.endpoints.geocode}`, {
                params: {
                    ...mapConfig.rest,
                    limit: 1,
                    q: to.address.label,
                }
            });

            this.form = {
                ...this.form, ...quote, ...{
                    from: fromData.items[0],
                    to: toData.items[0],
                    collection_date: moment(collection_date, 'DD-MM-YYYY').toString(),
                }
            }

            if (this.paramTransportType) {
                this.form.transport_types = [this.paramTransportType];
            }
        },

        checkVehicleTypeRequired() {
            if ([null, false, 0, undefined].indexOf(this.paramTransportType) > -1) {
                return true
            } else {
                return this.paramTransportType === this.transportTypes.TRUCK;
            }
        },
    },
}
</script>