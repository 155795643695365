<template>
    <div v-if="user"
         class="card mt-5 mb-4"
    >
        <div class="card-body px-lg-4 py-4">
            <h5 class="text-primary mb-4 font-weight-medium">{{ number }}. Service details</h5>
            <div v-if="Object.keys(vehicles).length" class="row">
                <div v-if="showTruck"
                     class="col-lg-6 col-xl-5 col-xl2-3">
                    <label class="mb-3">Vehicle type needed</label>

                    <div v-if="disableTruck"
                         class="alert alert-warning">
                        <small class="d-block">None of the available vehicle types supports the dimensions entered into
                            the quote.</small>
                    </div>

                    <template v-for="(vehicle, vehicleId) in vehicles">
                        <Radio v-if="vehicle.visible"
                                :key="vehicleId"
                               :id="vehicleId"
                               name="service_details.vehicle_type"
                               :label="vehicle.name"
                               :tooltip="vehicle.tooltip"
                               :value="vehicleId"
                               v-on="$listeners"
                               :v="vServer['service_details.vehicle_type'] || v.service_details.vehicle_type"
                               :checked="form.service_details.vehicle_type === vehicleId"
                               :show-error="false"
                               :disabled="disableTruck"
                               @change="handleChangeTemperatureControlled"
                        />
                    </template>


                    <label class="mt-3 mb-3">Required temp. conditions</label>
                    <Select name="service_details.temp_conditions"
                            :options="!isCheckedTemperatureControlled ? [] : tempConditions"
                            :placeholder="!isCheckedTemperatureControlled ? 'not applying' : 'choose option'"
                            :showError="false"
                            :disabled="!isCheckedTemperatureControlled"
                            :selected="!isCheckedTemperatureControlled ? null : selectedTempConditions"
                            v-on="$listeners"
                            :v="vServer['service_details.temp_conditions'] || v.service_details.temp_conditions"
                    />
                </div>
                <div class="col-lg-6 col-xl2-4  mt-5 mt-lg-0">
                    <label class="mb-3">Additional services</label>
                    <template v-for="service in additionalServices">
                        <Checkbox
                            :key="service.name"
                            :id="service.name"
                            :name="`service_details.additional_services[${service.name}]`"
                            :label="service.label"
                            :tooltip="service.tooltip"
                            v-on="$listeners"
                            v-if="showService(service)"
                            :checked="form.service_details.additional_services.includes(service.name)"
                            @change="service.name === 'hazardous_goods' ? handleChangeHazardousGoods($event) : ''"
                        />
                        <Select :key="`select-${service.name}`"
                                v-if="showSelectClassHazardousGoods && service.name === 'hazardous_goods'"
                                name="service_details.class_hazardous_goods"
                                class="my-2 options-wrap-normal"
                                :options="classHazardousGoods"
                                :selected="showSelectClassHazardousGoods && service.name ? selectedClassHazardousGoods : null"
                                v-on="$listeners"
                                :v="v.service_details.class_hazardous_goods"/>
                    </template>
                </div>
                <div class="col mt-5 mt-xl2-0">
                    <label class="mb-3">Extra cargo insurance</label>
                    <Checkbox
                        name="service_details.insurance"
                        :label="`Add insurance (insurance covers goods value only, up to ${formattedDefaultInsuranceValue})`"
                        @change="handleChangeInsurance"
                        :checked="insurance"
                        v-on="$listeners"
                    />
                    <p class="mb-0 mt-3 d-flex align-items-start w-100">
                        <Input name="service_details.insurance_value"
                               type="number"
                               class-name="form-control-sm"
                               :value="(isCheckedInsurance ? insuranceValue : null)"
                               :showError="false"
                               :disabled="!isCheckedInsurance"
                               placeholder="Goods value"
                               @change="handleChangeInsuranceValue"
                               v-on="$listeners"
                               :v="vServer['service_details.insurance_value'] || v.service_details.insurance_value"
                        />
                        <button class="ml-3 btn btn-sm btn-primary" type="button" @click.prevent="showInsurance">
                            Insurance policy
                        </button>
                    </p>
                </div>
            </div>
            <div v-else class="alert alert-warning mb-0">
                There are no vehicles matching your criteria
            </div>
        </div>

        <InsuranceModal :show="showModalInsurance" @hideModal="showModalInsurance = false"/>
    </div>
</template>

<script>
import Checkbox from "@/components/form/Checkbox";
import Radio from "@/components/form/Radio";
import Select from "@/components/form/Select";
import Input from "@/components/form/Input";
import {additional_services, temp_conditions, class_hazardous_goods, transportTypes} from "@/config/checkout"
import {mapGetters} from "vuex";
import InsuranceModal from "@/shared/new-quote/InsuranceModal";

export default {
    name: "ServiceDetails",
    components: {InsuranceModal, Input, Select, Checkbox, Radio},
    created() {
        if (this.form.service_details.insurance) {
            this.insuranceValue = this.form.service_details.insurance_value;
            this.insurance = this.form.service_details.insurance
        } else {
            this.insuranceValue = this.castDefaultInsuranceValue;
        }

        if (this.form.service_details.vehicle_type) {
            this.handleChangeTemperatureControlled({
                value: this.form.service_details.vehicle_type
            })
        }

        this.selectedTempConditions = this.form.service_details.temp_conditions;
        this.selectedClassHazardousGoods = this.form.service_details.class_hazardous_goods;
    },
    data: () => ({
        isCheckedTemperatureControlled: false,
        isCheckedInsurance: false,
        insurance: null,
        insuranceValue: 0,
        defaultInsuranceValue: 500000,
        additionalServices: additional_services,
        tempConditions: temp_conditions,
        classHazardousGoods: class_hazardous_goods,
        selectedVehicleType: null,
        selectedTempConditions: null,
        selectedClassHazardousGoods: null,
        showSelectClassHazardousGoods: false,
        showModalInsurance: false,
        transportTypes
    }),
    props: {
        form: Object,
        v: Object,
        vServer: Object,
        vehicles: Object,
        showTruck: {
            type: Boolean,
            default: true,
        },
        disableTruck: {
            type: Boolean,
            default: true,
        },
        number: {
            type: Number,
            default: 3
        }
    },
    watch: {
        $props: {
            deep: true,
            immediate: true,
            handler() {
                const {vehicle_type} = this.form.service_details;
                if (!vehicle_type) {
                    this.additionalServices = additional_services;
                }
                this.handleChangeTemperatureControlled({value: vehicle_type});
            },
        },
        selectedVehicleType(value) {
            const vehicleType = this.vehicles[value] ?? null;

            if (!vehicleType) {
                return
            }

            const {allowed_additional_services = []} = vehicleType

            if (allowed_additional_services.length) {
                this.additionalServices = additional_services.filter(service => allowed_additional_services.indexOf(service.name) > -1)
                additional_services.map(service => {
                    if (!this.additionalServices.find(s => s.name === service.name)) {
                        this.$emit('change', {
                            name: `service_details.additional_services[${service.name}]`,
                            value: null,
                        });
                    }
                })
            }
        }
    },
    computed: {
        userCurrency() {
            return this.currencies.currencies[this.user.settings.currency];
        },

        defaultCurrency() {
            return this.currencies.currencies[this.currencies.defaultCurrency];
        },

        castDefaultInsuranceValue() {
            const exchange = this.defaultCurrency.exchange[this.user.settings.currency] || 1;
            return this.defaultInsuranceValue * exchange
        },

        formattedDefaultInsuranceValue() {
            const defaultInsurance = this.castDefaultInsuranceValue / 1000;
            if (this.userCurrency.code === 'PLN') {
                return `${defaultInsurance}k ${this.userCurrency.symbol}`
            } else {
                return `${this.userCurrency.symbol}${defaultInsurance}k`
            }
        },

        ...mapGetters({
            'user': 'auth/user',
            'currencies': 'units/currencies',
        }),
    },
    methods: {
        handleChangeInsurance({value}) {
            this.isCheckedInsurance = value;
            if (!this.isCheckedInsurance) {
                this.insuranceValue = this.castDefaultInsuranceValue;
            }
        },
        handleChangeTemperatureControlled({value}) {
            this.selectedVehicleType = value;
            this.isCheckedTemperatureControlled = value === 'temperature';
        },
        handleChangeInsuranceValue({value}) {
            if (!value) return;
            this.insuranceValue = parseInt(value);
            if (isNaN(this.insuranceValue)) {
                this.insuranceValue = this.castDefaultInsuranceValue;
            }
        },
        handleChangeHazardousGoods({value}) {
            this.showSelectClassHazardousGoods = value;
            if (!value) {
                this.selectedClassHazardousGoods = null;
                this.$emit('change', {
                    name: 'service_details.class_hazardous_goods',
                    value: null
                })
            }
        },

        async showInsurance() {
            await this.$store.dispatch('terms/fetchInsurance');
            this.showModalInsurance = true;
        },

        showService(service) {
            if (service.showIfTruck) {
                return this.showTruck && this.form.transport_types && this.form.transport_types.indexOf(this.transportTypes.TRUCK) > -1;
            }
            return true;
        }
    }
}
</script>

<style lang="scss">
.options-wrap-normal {
    .dropdown-item {
        white-space: normal !important;
    }
}
</style>