<template>
    <div class="card mt-5">
        <div class="card-body px-lg-4 py-4">
            <h5 class="text-primary mb-4 font-weight-medium">{{ number }}. Shipping Route</h5>
            <div class="row">
                <div class="col-xl-4 col-xl3-5">
                    <InputSearchAddress
                        label="From"
                        name="from"
                        placeholder="Shipper Location"
                        :value="form.from ? form.from.address : {}"
                        v-on="$listeners"
                        :v="vServer['from.position'] || vServer['from.address.stateCode'] || vServer['from.address.county'] || vServer['from.resultType'] || v.from"
                    />
                </div>
                <div class="col-xl-4 col-xl3-5">
                    <InputSearchAddress
                        label="To"
                        name="to"
                        :value="form.to ? form.to.address : {}"
                        placeholder="Consignee Location"
                        v-on="$listeners"
                        :v="vServer['to.position'] || vServer['to.address.stateCode'] || vServer['to.address.county'] || vServer['to.resultType'] || v.to"
                    />
                </div>
                <div class="col-xl-4 col-xl3-2">
                    <InputCalender
                        name="collection_date"
                        label="Collection date"
                        placeholder="Date"
                        :minDate="minDate"
                        :value="form.collection_date || minDate"
                        v-on="$listeners"
                        :v="vServer['collection_date'] || v.collection_date"
                    />
                </div>
            </div>

            <div class="row mt-2" v-if="Object.values(transportTypes).indexOf($route.params.type) === -1">
                <div class="col-xl-4 col-xl3-5">
                    <InputTransportType
                        label="Choose the transport type"
                        name="transport_types"
                        :checked="form.transport_types"
                        :values="filterTransportTypes"
                        v-on="$listeners"
                        :v="vServer['transport_types'] || v.transport_types"
                    />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import InputSearchAddress from "@/components/form/InputSearchAddress";
import InputCalender from "@/components/form/InputCalender";
import InputTransportType from "@/components/form/InputTransportType";

export default {
    name: "ShippingRoute",
    components: {InputTransportType, InputCalender, InputSearchAddress},
    props: {
        form: Object,
        v: Object,
        vServer: Object,
        number: {
            type: Number,
            default: 1
        },
        transportTypes: Object
    },
    computed: {
        minDate() {
            return new Date();
        },
        filterTransportTypes(){
            const notAllowed = ['PARCEL'];

            return Object.keys(this.transportTypes)
                .filter(key => !notAllowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.transportTypes[key];
                    return obj;
                }, {})
        }
    },
}
</script>