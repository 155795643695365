import { helpers } from 'vuelidate/lib/validators'
import {isCountryInContinents} from "../helpers";

const notAddressType = (addressType, continents = []) => helpers.withParams(
    {type: 'notAddressType', addressType, continents},
    (value) => __notAddressType(value, addressType, continents)
)

const __notAddressType = (value, addressType, continents = []) => {
    if(continents.length && !isCountryInContinents(value?.address?.countryCode, continents)){
        return true
    }

    if(Array.isArray(addressType)){
        return !helpers.req(value)
            || (addressType.indexOf(value.resultType) === -1
                && addressType.indexOf( value?.localityType) === -1);
    }
    return !helpers.req(value)
        || (addressType !== value.resultType
            && addressType !== value?.localityType);
}

export default notAddressType;