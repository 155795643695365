<template>
    <div class="form-group">
        <label v-if="label" :for="id || name">{{ label }}</label>
        <InputHereAddress
            v-if="input == 'here'"
            :label="label"
            :name="name"
            :id="id || name"
            :className="!isValid ? 'is-invalid' : ''"
            :class="!isValid ? 'is-invalid' : ''"
            :placeholder="placeholder"
            :currentAddress="address ? address : null"
            @findAddresses="setAddresses"
            @click="showAddresses = true"
            @focusin="showAddresses = true"
            @focusout="showAddresses = false"
        />
        <transition name="fade">
            <Addresses v-if="addresses.length && showAddresses"
                       :addresses="addresses"
                       @chooseAddress="setAddress"
            />
        </transition>
        <div v-if="!isValid && typeof v === 'string' && showError" class="invalid-feedback text-nowrap">{{ v }}</div>
        <div v-else-if="!isValid && !v.required && showError" class="invalid-feedback">The field is required.</div>
        <div v-else-if="!isValid && !v.sameAs && typeof v.sameAs !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">This field must be the same like '{{ v.$params.sameAs.eq }}' field.
        </div>
        <div v-else-if="!isValid && !v.notSameAs && typeof v.notSameAs !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">This field must be different from the
            '{{ v.$params.notSameAs.notEqualTo }}' field.
        </div>
        <div v-else-if="!isValid && !v.addressType && typeof v.addressType !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">The field must contain a more specific address.
        </div>
        <div v-else-if="!isValid && !v.notAddressType && typeof v.notAddressType !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">The field must contain a more specific address.
        </div>
        <div v-else-if="!isValid && !v.notAddressTypeForNotAsia && typeof v.notAddressTypeForNotAsia !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">The field must contain a more specific address.
        </div>
        <div v-else-if="!isValid && !v.notAddressTypeForAsia && typeof v.notAddressTypeForAsia !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">The field must contain a more specific address.
        </div>
    </div>
</template>

<script>
import config from "@/config/maps";
import Addresses from "./InputSearchAddress/Addresses";
import InputHereAddress from "./InputSearchAddress/InputHereAddress";


export default {
    name: "InputSearchAddress",
    components: {InputHereAddress, Addresses},
    data: () => ({
        addresses: [],
        showAddresses: true,
        address: {},
        input: config.use,
    }),
    mounted() {
        if (this.value)
            this.address = this.value;
    },
    props: {
        label: String,
        id: String,
        placeholder: String,
        name: {
            type: String,
            required: true
        },
        value: {
            type: Object,
        },
        showError: {
            type: Boolean,
            default: true,
        },
        v: [Object, String, undefined, null],
    },
    methods: {

        setAddresses(response) {
            this.showAddresses = true;
            this.addresses = response;
            this.address = {};
            this.emitEvent({
                name: this.name,
                value: null
            })
        },

        setAddress(address) {
            this.address = address;
            this.emitEvent({
                name: this.name,
                value: this.address
            })

            this.showAddresses = false;

        },

        emitEvent(data = {}) {
            this.$emit('change', data);
        }
    },
    computed: {
        isValid() {
            if ([null, undefined].indexOf(this.v) > -1) return true;
            return typeof this.v === 'string' ? false : !this.v.$error
        }
    }
}
</script>

<style lang="scss">
.fade-leave-active,
.fade-enter-active {
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;

    .dropdown-menu {
        transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    }
}

.fade-enter, .fade-leave-to {
    .dropdown-menu {
        opacity: 0;
        transform: scale(0.75) translateY(-19px);
    }
}

.fade-enter-to, .fade-leave {
    .dropdown-menu {
        opacity: 1;
        transform: scale(1) translateY(0);
        pointer-events: auto;
    }
}

</style>