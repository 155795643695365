<template>
    <div class="form-group">
        <label v-if="label" class="d-block">{{ label }}</label>

        <label class="form-label-transport-type form-label-transport-type-all"
               :class="{active: checkedAll}"
               @click.prevent="handleCheckedAll"
        >
            <span>All</span>
        </label>

        <label v-for="(value) in values"
               :key="`${name}-${value}`"
               class="form-label-transport-type"
               :class="{
                   active: isChecked(value),
                   'is-invalid' : !isValid,
               }"
               :style="{
                   'background-image': `url(${require(`@/assets/img/transports/${value}.svg`)})`
               }"

        >
            <input type="checkbox"
                   class="sr-only"
                   :class="{'is-invalid' : !isValid}"
                   :id="`${name}-${value}`"
                   :name="`${name}[]`"
                   :value="value"
                   @change="handleOnChange"
            >
        </label>

        <div v-if="!isValid && typeof v === 'string' && showError" class="invalid-feedback text-nowrap">{{ v }}</div>
        <div v-else-if="!isValid && !v.required && typeof v.required !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">The field is required.
        </div>
        <div v-else-if="!isValid && !v.array && typeof v.array !== 'undefined' && showError"
             class="invalid-feedback text-nowrap">The field must be an array.
        </div>
        <div v-else-if="!isValid && Object.values(v.$each.$iter).length && showError"
             v-for="(validator, index) in v.$each.$iter"
             :key="index"
             class="invalid-feedback text-nowrap">
            <template v-if="!validator.oneOf">
                The selected '{{ validator.$model }}' is invalid.
            </template>
        </div>
    </div>
</template>

<script>

export default {
    name: "InputTransportType",
    data: () => ({
        checkedAll: false,
        includedChecked: [],
    }),
    mounted() {
        this.includedChecked = this.checked;
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        checked: {
            type: Array,
            default: () => [],
        },
        values: {
            type: Object,
            required: true,
        },
        value: {
            type: [String, Number],
            default: 1,
        },
        label: {
            type: String,
            default: '',
        },
        id: String,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        showError: {
            type: Boolean,
            default: true,
        },
        v: Object,
    },
    watch: {
        includedChecked(checked) {
            const {name, disabled, readonly} = this;

            if (disabled || readonly) return;

            this.checkedAll = checked.length === this.valuesArray.length

            const value = checked || null;
            this.$emit('change', {name, value});
        }
    },
    methods: {
        isChecked(value) {
            return this.includedChecked.indexOf(value) > -1;
        },
        handleCheckedAll() {
            this.checkedAll = !this.checkedAll;

            if (this.checkedAll) {
                this.includedChecked = Object.values(this.values);
            } else {
                this.includedChecked = [];
            }
        },
        handleOnChange({target}) {
            const {value} = target;

            if (this.includedChecked.indexOf(value) > -1) {
                this.includedChecked.splice(
                    this.includedChecked.indexOf(value), 1
                )
            } else {
                this.includedChecked.push(value)
            }
        }
    },
    computed: {
        valuesArray() {
            return Object.values(this.values);
        },
        isValid() {
            if ([null, undefined].indexOf(this.v) > -1) return true;
            return typeof this.v === 'string' ? false : !this.v.$error
        }
    },
}
</script>

<style scoped lang="scss">
.form-label-transport-type {
    width: 45px;
    height: 45px;
    background-color: $gray-400;
    cursor: pointer;
    margin-bottom: 0;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;

    &.active {
        background-color: $primary;
    }

    &-all{
        position: relative;
        span{
            position: absolute;
            left: 0;
            width: 100%;
            display: flex;
            height: 100%;
            top:0;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: $h4-font-size;
        }
    }
    & + & {
        margin-left: 10px;
    }
}
</style>