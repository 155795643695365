<template>
    <a
        v-on="$listeners"
        href="#"
        class="dropdown-item"
        @mousedown.prevent="chooseAddress"
        @click.prevent
    >
        {{ address.label }}
    </a>
</template>

<script>
export default {
    name: "AddressItem",
    props: {
        address: {
            required: true,
            type: Object,
        }
    },
    methods: {
        chooseAddress() {
            this.$emit('chooseAddress', this.address);
        }
    }
}
</script>