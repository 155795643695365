import { helpers } from 'vuelidate/lib/validators'

const isEqual = (...objects) => objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));

const notSameAs = (notEqualTo) => helpers.withParams(
    {type: 'notSameAs', notEqualTo},
    (value, parentVm) => {
        return !helpers.req(value) || !isEqual(value ,helpers.ref(notEqualTo, this, parentVm));
    }
)
export default notSameAs;
