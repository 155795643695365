<template>
    <div class="custom-control custom-checkbox">
        <input type="checkbox"
               class="custom-control-input"
               :class="{'is-invalid' : !isValid, [className]: className}"
               v-model="isChecked"
               :id="id || name"
               :name="name"
               :value="value"
               v-on="$listeners">
        <label class="custom-control-label" :for="id || name">
            <slot name="beforeLabel"></slot>
            {{ label }}
            <slot name="afterLabel"></slot>
            <Tooltip v-if="$attrs.tooltip"
                     :style="{marginLeft: '5px'}"
                     :content="$attrs.tooltip"
            />
        </label>

        <div v-if="!isValid && typeof v === 'string' && showError" class="invalid-feedback text-nowrap">{{ v }}</div>
        <div v-else-if="!isValid && !v.required && typeof v.required !== 'undefined' && showError" class="invalid-feedback text-nowrap">The field is required.</div>

    </div>
</template>

<script>
import Tooltip from "@/components/notices/InfoTooltip";
export default {
    name: "Checkbox",
    components: {Tooltip},
    data: () => ({
        isChecked: null,
    }),
    mounted() {
        this.isChecked = this.checked;
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        checked: {
            type: [Boolean, Number],
            default: false,
        },
        value: {
            type: [String, Number],
            default: 1,
        },
        label: {
            type: String,
            default: '',
        },
        id: String,
        className: String,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        showError: {
            type: Boolean,
            default: true,
        },
        v: Object,
    },
    watch: {
        isChecked(checked){
            const {name, disabled, readonly} = this;
            
            if(disabled || readonly) return;

            let {value} = this;
            value = checked ? value : null;
            this.$emit('change',{name,value});
        },
    },
    computed: {
        isValid() {
            if([null,undefined].indexOf(this.v) > -1) return true;
            return typeof this.v === 'string' ? false : !this.v.$error
        }
    },
}
</script>

<style scoped lang="scss">
.custom-checkbox{
    margin-bottom: 0.45rem;
}
.custom-control-label{
    font-weight: $font-weight-normal;
    line-height: 1.75em;
}
</style>