<template>
    <div class="form-group">
        <label v-if="label" :for="id || name">{{ label }}</label>
        <div class="position-relative">
            <DatePicker v-model="includedValue"
                        mode="date"
                        is-expanded
                        title-position="left"
                        :minDate="minDate"
                        is-required
                        :masks="masks"
                        :popover="{transition:`fade-slide`, visibility: 'click'}">
                <template v-slot="{ inputValue, inputEvents }">
                    <input
                        readonly
                        class="form-control"
                        :class="{'is-invalid' : !isValid, [className]: className}"
                        type="text"
                        :id="id || name"
                        :name="name"
                        :placeholder="placeholder"
                        :value="inputValue"
                        v-on="inputEvents"
                        autocomplete="off"
                        @input="selectedDate = null"
                    />
                    <div v-if="!isValid && typeof v === 'string' && showError" class="invalid-feedback">{{ v }}</div>
                    <div v-else-if="!isValid && !v.required && typeof v.required !== 'undefined' && showError" class="invalid-feedback">ield is required.</div>
                    <div v-else-if="!isValid && !v.date && typeof v.date !== 'undefined' && showError" class="invalid-feedback">Date format is invalid.</div>
                </template>
            </DatePicker>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: "InputCalender",
    components: {DatePicker},
    data: () => ({
        includedValue: null,
        masks: {
            input: 'DD-MM-YYYY',
        }
    }),
    mounted() {
        if(this.value)
            this.includedValue = moment(this.value).toString();
    },
    props: {
        label: String,
        id: String,
        className: String,
        placeholder: String,
        minDate: [String, Date],
        name: {
            type: String,
            required: true
        },
        value: {
            type: [Date, String],
            default: new Date(),
        },
        showError: {
            type: Boolean,
            default: true,
        },
        v: [Object,String,undefined,null],
    },
    watch: {
        includedValue(value){
            value = moment(value).toString();
            const {name} = this;
            this.$emit('change',{name, value})
        }
    },
    computed: {
        isValid() {
            if([null,undefined].indexOf(this.v) > -1) return true;
            return typeof this.v === 'string' ? false : !this.v.$error
        }
    }
}
</script>
<style scoped lang="scss">
$icon: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' fill='#{$gray-600}'><path fill-rule='evenodd' d='M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z'/><path fill-rule='evenodd' d='M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/></svg>")
;


.form-control {
    padding-right: $input-height-inner;
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter center;
    background-image: escape-svg($icon);
    background-color: $input-bg;
    cursor: pointer;
}
</style>
<style lang="scss">

.form-group {
    .vc-popover-content-wrapper {
        left: 50% !important;
        transform: translate(-50%, 100%) !important;
        bottom: 0 !important;
        top: auto !important;
    }

    .vc-popover-content {
        border: $input-border-width solid $input-border-color;
        box-shadow: none;
        transform-origin: top;

        &.direction-bottom {
            margin: 0.125rem 0 0;
        }
    }

    .vc-popover-caret {
        display: none;
    }

    .vc-highlight {
        background-color: $primary !important;
    }

}


.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    pointer-events: none;
}

.fade-slide-enter,
.fade-slide-leave-to {
    opacity: 0;

    &.direction-bottom {
        transform: scale(0.75) translateY(-19px);
    }

}

.fade-slide-enter-to,
.fade-slide-leave {
    opacity: 1;

    &.direction-bottom {
        transform: scale(1) translateY(0);
        pointer-events: auto;
    }
}
</style>