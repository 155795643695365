export const transportTypes = {
    TRUCK: 'truck',
    AIR: 'air',
    SEA: 'sea',
    PARCEL: 'parcel',
}

export const transportBasicTypes = {
    FCL: 'fcl',
    OCEAN_STANDARD: 'ocean_standard'
}


export const offerTypes = {
    GEPAX: 'gepax',
    EXFREIGHT: 'exfreight',
}

export const TAILLIFT_MAX_LIFT = 700;

export const HAZARDOUS_GOODS = 'hazardous_goods';
export const GARMENTS_HANGERS = 'garments_hangers';
export const TAILLIFT_LOAD_AND_UNLOAD = 'taillift_load_and_unload';
export const ASSISTANCE_LOADING_AND_OFFLOADING = 'assistance_loading_and_offloading';
export const FRAGILE_GOODS = 'fragile_goods';

export const additional_services = [
    {
        name: HAZARDOUS_GOODS,
        label: 'Hazardous goods (ADR licence required)',
        tooltip: 'Including Dangerous Goods Safety Advices review',
    },
    {
        name: GARMENTS_HANGERS,
        label: 'Garments on Hangers',
        showIfTruck: true,
    },
    {
        name: TAILLIFT_LOAD_AND_UNLOAD,
        label: 'Tail-lift to load & unload'
    },
    {
        name: ASSISTANCE_LOADING_AND_OFFLOADING,
        label: 'Assistance with loading & offloading'
    },
    {
        name: FRAGILE_GOODS,
        label: 'Fragile goods',
        tooltip: 'Including vehicle equipped with pneumatic suspension (rear axle only)',
        showIfTruck: true,
    }
];

export const DOOR_TO_DOOR = 'door_to_door';
export const DOOR_TO_PORT = 'door_to_port';
export const PORT_TO_DOOR = 'port_to_door';

export const doorServices = {
    [DOOR_TO_DOOR]: {
        value: DOOR_TO_DOOR,
        label: 'Door to Door'
    },
    [DOOR_TO_PORT]: {
        value: DOOR_TO_PORT,
        label: 'Door to Port'
    },
    [PORT_TO_DOOR]: {
        value: PORT_TO_DOOR,
        label: 'Port to Door'
    }
}


export const temp_conditions = [
    null,
    '-15/-25°C',
    '-2/-8°C',
    '+2/+8°C',
    '+15/+25°C',
];

// can set above country code in ISO 3166-1 alpha-3 format
// and country code with state code or postal code or county name, all without spaces, separator char '-'
// examples:
// POL, ESP-CN, ESP-35600 POL-64920, PRT-MadeiraIsland
export const country_rules = {
    disabled: [],
    enabled: [],
    from: {
        disabled: [],
        enabled: [],
    },
    to: {
        disabled: [],
        enabled: [],
    },
}

export const class_hazardous_goods = [
    null,
    'Class 2 Gases, including compressed, liquified, and dissolved under pressure gases and vapors Flammable gases (e.g. butane, propane, acetylene) Non-flammable and non-toxic, likely to cause asphyxiation (e.g. nitrogen, CO2) or oxidisers (e.g. oxygen) Toxic (e.g. chlorine, phosgene)',
    'Class 3 Flammable liquids',
    'Class 4.1 Flammable solids, self-reactive substances, and solid desensitized explosives',
    'Class 4.2 Substances liable to spontaneous combustion',
    'Class 4.3 Substances which, in contact with water, emit flammable gases',
    'Class 5.1 Oxidizing substances',
    'Class 5.2 Organic peroxides',
    'Class 6.1 Toxic substances',
    'Class 6.2 Infectious substances',
    'Class 8 Corrosive substances',
    'Class 9 Miscellaneous dangerous substances and articles'
]