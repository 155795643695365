<template>
    <div class="dropdown"
         v-on="$listeners"
         @mouseleave="hoverAddress = null">
        <ul class="dropdown-menu show w-100">
            <AddressItem v-for="address in addresses"
                         v-on="$listeners"
                         :address="address"
                         :key="address.id"
                         :class="{ 'active': hoverAddress === address }"
                         @mouseenter="hoverAddress = address"
           />
        </ul>
    </div>
</template>

<script>
import AddressItem from "./AddressItem";
export default {
    name: "Addresses",
    components: {AddressItem},
    data: () => ({
        hoverAddress: null,
    }),
    props: {
        addresses: {
            request: true,
            type: Array,
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
    },

    methods: {
        handleKeyDown(e) {
            const {keyCode} = e;
            if([38, 40, 13].indexOf(keyCode) === -1)
                return true;

            switch (keyCode) {
                case 38: // up
                case 40: {// down
                    let hoverAddressIndex = this.hoverAddressIndex + ((keyCode == 40) ? 1 : -1);

                    if (hoverAddressIndex < 0) hoverAddressIndex = 0
                    else if (hoverAddressIndex > this.addresses.length - 1)
                        hoverAddressIndex = this.addresses.length - 1;

                    this.hoverAddress = this.addresses[hoverAddressIndex];
                    break;
                }

                case 13: { // enter
                    if (this.hoverAddress) {
                        this.$emit('chooseAddress', this.hoverAddress);
                    }
                    break;
                }
            }
        },
    },
    computed: {
        hoverAddressIndex(){
            return this.addresses.findIndex((item) => item === this.hoverAddress);
        }
    }

}
</script>