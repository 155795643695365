<template>
    <div :class="{
        'form-group-loading': loading
    }">
        <input type="search"
               :id="id || name"
               class="form-control"
               :class="className"
               :name="name"
               :value="search"
               :placeholder="placeholder"
               @input="searching"
               @search="searching"
               autocomplete="off"
               v-on="$listeners"
               @keyup="handleKeyUp"
               @keypress="handleKeyPress"
        />
        <div v-if="loading"
             class="spinner-border text-primary"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

</template>

<script>
import debounce from "debounce";
import config from "@/config/maps";
import {country_rules as countryRules} from '@/config/checkout';

export default {
    name: "InputHereAddress",
    data: () => ({
        loading: false,
        search: '',
        searched: [],
    }),
    props: {
        label: String,
        id: String,
        placeholder: String,
        className: String,
        name: {
            type: String,
            required: true
        },
        currentAddress: Object,
    },
    watch: {
        currentAddress() {
            if (typeof this.currentAddress.label !== "undefined") {
                this.search = this.currentAddress.label;
            }
        }
    },
    methods: {
        handleKeyUp(e) {
            this.search = e.target.value;
        },
        handleKeyPress(e) {
            const code = e.keyCode || e.which;
            if (code === 13) {
                e.preventDefault();
                return false;
            }
        },

        searching: debounce(function (e) {
            if(this.loading)
                return false;

            if (e.target.value === '')
                this.search = e.target.value;

            if (this.currentAddress.label === this.search) return;

            if (this.checkSearch(this.search)) {
                this.loading = true;
                this.getAddresses(this.search)
                    .then((response) => {
                        this.searched = response.data.items || [];
                        this.$emit('findAddresses', this.addresses);
                        this.loading = false;
                    })
            } else this.$emit('findAddresses', []);
        }, 500),

        checkSearch(value) {
            return value.length > 3 && value.replace(/\s/g, '').length;
        },

        async getAddresses(value) {
            const mapConfig = config.services[config.use];
            return await this.$http.get(`${mapConfig.url}${mapConfig.endpoints.geocode}`, {
                params: {
                    ...mapConfig.rest,
                    q: value,
                }
            });
        }
    },

    computed: {

        addresses() {
            return this.searched.map((point) => {
                point.label = point?.address?.label || point.title;
                return point;
            }).filter(({address}) => {
                const checkAddresses = [address.countryCode.toLowerCase()];
                for (const prop of ['postalCode', 'stateCode', 'county']) {
                    if (address[prop]) {
                        checkAddresses.push(`${address.countryCode.toLowerCase()}-${address[prop].toLowerCase().replace(/[&\\/\\\\#,+()$~%-.'":*?<>{}\s]/gi, '')}`)
                    }
                }
                const {enabled, disabled} = this.rulesAddresses
                if (enabled.length) {
                    if (!enabled.filter(allow => checkAddresses.includes(allow)).length) {
                        return false;
                    }
                }
                if (disabled.length) {
                    if (disabled.filter(allow => checkAddresses.includes(allow)).length) {
                        return false;
                    }
                }
                return true;
            });
        },
        rulesAddresses() {
            const rules = {
                enabled: countryRules.enabled || [],
                disabled: countryRules.disabled || [],
            }
            for (const [key] of Object.entries(rules)) {
                if (countryRules[this.name] && Array.isArray(countryRules[this.name][key])) {
                    rules[key] = [...new Set(rules[key].concat(countryRules[this.name][key]))].map(rule => rule.toLowerCase());
                }
            }
            return rules;
        }
    }
}
</script>

<style scoped lang="scss">
$icon: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='#{$gray-600}' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z'/></svg>");

.form-control {
    padding-right: $input-height-inner;
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter center;

    &:not(.is-invalid) {
        background-image: escape-svg($icon);
    }
}
</style>