<template>
    <div class="card mt-5 mb-4">
        <div class="card-body px-lg-4 pt-lg-3 pb-6 pb-xl2-5">
            <h5 class="text-primary mb-4 font-weight-medium d-flex align-items-center">
                {{ number }}. Items

                <div class="col-auto ml-auto col-units">
                    <Select
                        name="items_unit"
                        v-on="$listeners"
                        :selected="form.items_unit"
                        :options="castUnits"
                        :required="true"
                        :show-error="false"
                        :v="vServer.items_unit || v.items_unit"
                        @change="handleChangeItemsUnit"
                    />
                </div>

            </h5>
            <div class="row items-header justify-content-between"
            >
                <div class="col-auto text-center px-1" :style="{width: '50px'}"><label class="mb-0">Id</label></div>
                <div class="col col-name"><label class="mb-0">Name</label></div>
                <div class="col col-weight text-center"><label class="mb-0">Weight</label></div>
                <div class="col col-length text-center"><label class="mb-0">Length</label></div>
                <div class="col col-width text-center"><label class="mb-0">Width</label></div>
                <div class="col col-height text-center"><label class="mb-0">Height</label></div>
                <div class="col col-stackable text-center"><label class="mb-0">Stackable</label></div>
                <div class="col-auto col-quantity text-center"><label class="mb-0">Quantity</label></div>
            </div>
            <Item v-for="item of castItems"
                  :key="item.id"
                  :item="item"
                  :units="units"
                  :canRemove="items.length > 1"
                  @removeItem="removeItem"
                  :v="vServerCast[item.id-1] || v.items.$each.$iter[item.id-1]"
            />
            <p v-if="castGrossWeight || castShipmentVolume" class="items-details mb-0 mt-4 mb-5 mb-xl2-0">
                <span v-if="castGrossWeight" class="font-weight-medium d-block d-lg-inline-block">
                    Gross weight:
                    <strong class="font-weight-semibold text-primary">{{ castGrossWeight }}</strong>
                </span>
                <span v-if="castShipmentVolume" class="font-weight-medium ml-lg-7">
                    Shipment volume:
                    <strong class="font-weight-semibold text-primary">{{
                            castShipmentVolume
                        }}<sup>3</sup></strong>
                </span>
            </p>
            <button class="btn btn-primary btn-add-item align-items-center d-flex"
                    @click="addItem"
                    type="button"
                    role="presentation"
                    tabindex="-1">
                <svg class="mr-2" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Add item
            </button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Item, {ItemObject} from "@/shared/new-quote/Item";
import Select from "@/components/form/Select";

export default {
    name: "Items",
    components: {Select, Item},
    data: () => ({
        items: [new ItemObject(1)],
    }),
    props: {
        form: Object,
        v: Object,
        vServer: Object,
        number: {
            type: Number,
            default: 2
        }
    },
    created() {
        if (this.form.items.length) {
            this.items = this.form.items;
        }

        this.emitEvent({items: this.items, validate: false});
    },
    methods: {
        addItem() {
            const id = this.items.length + 1;
            this.items.push(new ItemObject(id));
            this.emitEvent();
        },
        removeItem(item) {
            const index = this.items.findIndex((i) => i === item);
            this.items.splice(index, 1);
            this.emitEvent();
        },
        emitEvent(data = {}) {
            this.$emit('changeItems', {
                ...data,
                name: 'items',
                value: this.items
            });
        },
        handleChangeItemsUnit({value}){
            this.$cookies.set('items-unit', value);
        }
    },
    computed: {
        ...mapGetters({
            'units': 'units/units',
        }),

        vServerCast() {
            const attributes = Object.keys(this.items[0]);
            let output = [];
            for (const [i,] of Object.entries(this.items)) {
                const data = {};
                for (const [, attr] of Object.entries(attributes)) {
                    const value = this.vServer[`items.${i}.${attr}`];
                    if (value) data[attr] = value
                }

                if (Object.keys(data).length)
                    output.push(data);
            }
            return output;
        },

        castItems() {
            return this.items.map((item, index) => {
                item.id = index + 1;
                return item;
            });
        },

        castUnits() {
            const units = {};
            for (const [key, value] of Object.entries(this.units)) {
                units[key] = value.short_label;
            }
            return units;
        },

        castGrossWeight() {
            const weight = this.items.reduce((previousValue, {weight, quantity}) => {
                if (!weight || !quantity) return previousValue;
                return previousValue + (weight ? parseFloat(weight) * quantity : 0);
            }, 0);

            const [, unit] = this.castUnits[this.form.items_unit].split('/');

            return `${weight.toFixed(2)} ${unit}`;
        },

        castShipmentVolume() {
            const volume = this.items.reduce((previousValue, {width, length, height, quantity}) => {
                if (!width || !length || !height || !quantity) return previousValue;
                const volume = parseFloat(width) * parseFloat(length) * parseFloat(height) * parseFloat(quantity)
                return previousValue + volume;
            }, 0);

            if(this.form.items_unit === 'cm'){
                return `${(volume / 1000000).toFixed(2)} m`;
            } else{
                return `${(volume).toFixed(2)} in`;
            }
        }
    }

}
</script>

<style scoped lang="scss">
.btn-add-item {
    position: absolute;
    right: 77px;
    bottom: 0;
    transform: translateY(50%);
    @include media-breakpoint-down('xl2') {
        right: 50px;
    }
    @include media-breakpoint-down('xl') {
        right: 1.5rem;
        transform: none;
        bottom: 1rem;
    }

    @include media-breakpoint-down('md') {
        width: calc(100% - 80px);
        justify-content: center;
    }
    @include media-breakpoint-down('sm') {
        right: 55px;
        width: calc(100% - 110px);
    }
    @include media-breakpoint-down('sm') {
        right: 15px;
        width: calc(100% - 30px);
    }
}

.items-header {
    padding-right: 50px;
    @include media-breakpoint-down('xl2') {
        padding-right: 35px;
    }
    @include media-breakpoint-down('xl') {
        display: none;
    }
}

.col {
    &-name,
    &-weight,
    &-length,
    &-width,
    &-height,
    &-stackable,
    &-quantity {
        @include media-breakpoint-only('xl2') {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &-weight,
    &-length,
    &-width,
    &-height {
        width: 115px;
        flex: 0 0 115px;

        @include media-breakpoint-down('xl2') {
            width: 105px;
            flex: 0 0 105px;
        }
    }

    &-stackable {
        width: 100px;
        flex: 0 0 100px;
    }

    &-quantity {
        width: 155px;
        flex: 0 0 155px;
        @include media-breakpoint-down('xl2') {
            width: 135px;
            flex: 0 0 135px;
        }

    }

    &-units {
        padding-right: 50px;
        min-width: 200px;

        @include media-breakpoint-only('xl2') {
            padding-right: 35px;
        }
        @include media-breakpoint-down('xl') {
            padding-right: 0;
        }
    }
}


.items-details {
    @include media-breakpoint-down('xl') {
        padding-left: 35px;
    }
    @include media-breakpoint-down('sm') {
        padding-left: 0;
    }
}
</style>